import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

export const useAuthUser = () => {
  const navigate = useNavigate();
  const user = useMemo(() => JSON.parse(localStorage.getItem('user')), []);
  const isSuperAdmin = useMemo(
    () => user?.roles?.some((role) => role.name === 'super_admin'),
    [user]
  );

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/');
  };

  return { isSuperAdmin, handleLogout };
};
