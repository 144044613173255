import React from 'react';
import { useProjectRfiList } from '../../hooks/useProjectRfiList';
import { RfiProvider } from './context/RfiProvider';
import PageLoader from '../../components/PageLoader';
import Header from '../../components/Header';
import RfiDetailsComponent from '../../components/RfiDetails/RfiDetails';

const RfiDetail = () => {
  const projectRfiHook = useProjectRfiList();

  if (projectRfiHook.isProjectRfiFetching) {
    return <PageLoader />;
  }

  return (
    <RfiProvider>
      <div className="wrapper">
        <Header />
        <div className="variation-detail max-w-[1392px] mx-auto  my-5 w-full">
          <RfiDetailsComponent />
        </div>
      </div>
    </RfiProvider>
  );
};

export default RfiDetail;
