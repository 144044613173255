import React, { useContext } from 'react';
import { rfiContext } from './RfiContext';
import ComponentLoader from '../../../components/ComponentLoader';
import { useProjectRfiList } from '../../../hooks/useProjectRfiList';

// eslint-disable-next-line react/prop-types
export const RfiProvider = ({ children }) => {
  const rfiDetailsData = useProjectRfiList();

  if (rfiDetailsData.isProjectRfiFetching) {
    return <ComponentLoader />;
  }

  return <rfiContext.Provider value={rfiDetailsData}>{children}</rfiContext.Provider>;
};

export const useRfiContext = () => {
  const context = useContext(rfiContext);
  if (!context) {
    throw new Error('useRfiContext must be used within a RfiProvider');
  }
  return context;
};
