import { toast } from 'react-toastify';

const commonToastOptions = {
  position: 'bottom-center',
  autoClose: 2500,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const showSuccessToast = (message) => {
  return toast.success(message, {
    ...commonToastOptions,
    className: 'custom-toast success-toast',
  });
};

export const showErrorToast = (message) => {
  return toast.error(message, {
    ...commonToastOptions,
    className: 'custom-toast error-toast ',
  });
};
