/* eslint-disable react/prop-types */
import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Zoom } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/zoom';
import { FiXCircle, FiArrowLeftCircle, FiArrowRightCircle } from 'react-icons/fi';

const ImageSlider = ({ images, currentIndex, onClose }) => {
  const swiperRef = useRef(null);

  const goNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 backdrop-filter backdrop-blur-md">
      <div className="relative w-full h-full max-w-5xl max-h-[70vh] bg-navy border border-border rounded-xl overflow-hidden">
        <button
          onClick={onClose}
          className="absolute top-4 right-4 text-white text-2xl z-10 rounded-full w-8 h-8 flex items-center justify-center"
        >
          <FiXCircle className="text-4xl" />
        </button>
        <Swiper
          ref={swiperRef}
          modules={[Navigation, Zoom]}
          zoom={{
            maxRatio: 3,
            minRatio: 1,
          }}
          loop
          spaceBetween={0}
          slidesPerView={1}
          initialSlide={currentIndex}
          className="w-full h-full"
        >
          {images.map((image, index) => {
            const extension = image.split('.').pop().toLowerCase();
            if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
              return (
                <SwiperSlide
                  key={index}
                  className="flex items-center justify-center pt-16 pb-4 px-4"
                >
                  <div className="w-full h-full flex items-center justify-center border border-border rounded-xl overflow-hidden">
                    <img
                      src={image}
                      alt={`Slide ${index}`}
                      className="object-cover h-full w-full"
                    />
                  </div>
                </SwiperSlide>
              );
            } else if (['mp4', 'webm', 'ogg'].includes(extension)) {
              return (
                <SwiperSlide
                  key={index}
                  className="flex items-center justify-center pt-16 pb-4 px-4"
                >
                  <div className="w-full h-full flex items-center justify-center border border-border rounded-xl overflow-hidden">
                    <video
                      src={image}
                      alt={`Slide video ${index}`}
                      className="object-cover h-full w-full"
                      controls
                    />
                  </div>
                </SwiperSlide>
              );
            }
          })}
        </Swiper>
        <button
          onClick={goPrev}
          className="absolute top-1/2 left-6 z-50 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-all duration-300"
        >
          <FiArrowLeftCircle className="text-4xl" />
        </button>
        <button
          onClick={goNext}
          className="absolute top-1/2 right-6 z-50 transform -translate-y-1/2 bg-black bg-opacity-50 text-white rounded-full hover:bg-opacity-75 transition-all duration-300"
        >
          <FiArrowRightCircle className="text-4xl" />
        </button>
      </div>
    </div>
  );
};

export default ImageSlider;
