import axios from 'axios';

const baseURL = process.env.REACT_APP_API_BASE_URL || 'https://your-api-base-url.com';

const axiosInstance = axios.create({
  baseURL,
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Handle specific error status codes
      switch (error.response.status) {
        case 401:
          // Clear local storage and redirect to login page
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          window.location.href = '/'; // Redirect to login page
          break;
        case 404:
          // Handle not found
          break;
        case 500:
          // Handle server error
          break;
        default:
        // Handle other errors
      }
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
