/* eslint-disable react/prop-types */
import React from 'react';

const ComponentLoader = ({ className = 'h-[75vh]' }) => {
  return (
    <div className={`${className} flex items-center w-full justify-center`}>
      <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-primary"></div>
    </div>
  );
};

export default ComponentLoader;
