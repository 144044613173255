import { createContext, useContext } from 'react';
const rfiContext = createContext({
  data: undefined,
  isFetching: false,
  isError: false,
  error: undefined,
});

const useRfiContext = () => useContext(rfiContext);

export { useRfiContext, rfiContext };
