import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import Button from '../Button';
import { useAuthUser } from '../../hooks/useAuthUser';

const Header = ({ createProject }) => {
  const { isSuperAdmin } = useAuthUser();
  // const [signup, setSignup] = useState(false);

  const location = useLocation();
  return (
    <header className="flex items-center justify-between ">
      <Link to="/">
        <img
          src="/images/quba-construction-w.png"
          alt="Sinq Constructions"
          width="132"
          height="27"
        />
      </Link>
      <div className="menu flex h-[62px] items-center gap-[31px] bg-navy border-border border rounded-b-[12px] px-[42px] text-gray">
        <Link
          to="/dashboard"
          className={`${location.pathname === '/dashboard' ? 'text-primary' : undefined}`}
        >
          <span className="icon-dashboard pr-[10px]"></span>Dashboard
        </Link>
        <Link
          to="/projects"
          className={`${
            location.pathname === '/projects' ||
            location.pathname === '/view-project*' ||
            location.pathname === '/create-project'
              ? 'text-primary'
              : undefined
          }`}
        >
          <span className="icon-projects pr-[10px]"></span>Projects
        </Link>
        <Link
          to="/account"
          className={`${location.pathname === '/account' ? 'text-primary' : undefined}`}
        >
          <span className="icon-user pr-[10px]"></span>Account
        </Link>
        <Link
          to="/setting"
          className={`${location.pathname === '/setting' ? 'text-primary' : undefined}`}
        >
          <span className="icon-setting pr-[10px]"></span>Settings
        </Link>
      </div>
      <Button to="/create-project" disabled={!isSuperAdmin} classes={createProject && 'invisible'}>
        {' '}
        New project <span className="icon-plus pl-[6px]"></span>
      </Button>
    </header>
  );
};

Header.propTypes = {
  createProject: PropTypes.bool,
};

export default Header;
