import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Button = ({ to, children, classes, style, onClick, disabled, isLoading, ...rest }) => {
  const buttonContent = (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-5 w-5 border-b-2 border-white mr-2"></div>
          Loading...
        </div>
      ) : (
        children
      )}
    </>
  );

  const buttonClasses = `flex justify-center items-center px-5 rounded-lg text-xs w-fit duration-300 hover:bg-primaryhover hover:text-white min-w-[90px] ${
    style === 'bordered' ? 'bg-navy border-border border text-xs' : 'bg-primary h-[32px]'
  } ${classes} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`;

  return (
    <>
      {to ? (
        disabled ? (
          <span className={buttonClasses} {...rest}>
            {buttonContent}
          </span>
        ) : (
          <Link to={to} className={buttonClasses} {...rest}>
            {buttonContent}
          </Link>
        )
      ) : (
        <button onClick={onClick} className={buttonClasses} disabled={disabled} {...rest}>
          {buttonContent}
        </button>
      )}
    </>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  children: PropTypes.node.isRequired,
  classes: PropTypes.string,
  style: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default Button;
