import React, { lazy } from 'react';
import ProtectedRoute from './ProtectedRoute';
import GuestRoute from './GuestRoute';
import RfiDetail from '../pages/RfiDetails';
import PublicRoute from './PublicRoute';

const Dashboard = lazy(() => import('../pages/Dashboard'));
const Login = lazy(() => import('../pages/Authentication/Login'));
const Project = lazy(() => import('../pages/Project'));
const ViewProject = lazy(() => import('../pages/ViewProject'));
const CreateProject = lazy(() => import('../pages/CreateProject'));
const Setting = lazy(() => import('../pages/Account/Settings'));
const Account = lazy(() => import('../pages/Account'));
const VariationDetail = lazy(() => import('../pages/VariationDetail'));
const AddVariation = lazy(() => import('../pages/AddVariation'));
const ClientPublicVariation = lazy(() => import('../pages/ClientPublicVariation'));
const ForgotPassword = lazy(() => import('../pages/Authentication/ForgotPassword'));
const ResetPassword = lazy(() => import('../pages/Authentication/ResetPassword'));

const routes = [
  {
    element: <GuestRoute />,
    children: [
      {
        path: '/',
        element: <Login />,
      },
      {
        path: '/forgot-password',
        element: <ForgotPassword />,
      },
      {
        path: '/reset-password/:resetToken',
        element: <ResetPassword />,
      },
    ],
  },
  {
    element: <ProtectedRoute />,
    children: [
      {
        path: '/dashboard',
        element: <Dashboard />,
      },
      {
        path: '/projects',
        element: <Project />,
      },
      {
        path: '/view-project/:projectId',
        element: <ViewProject />,
      },
      {
        path: '/create-project',
        element: <CreateProject />,
      },
      {
        path: '/setting',
        element: <Setting />,
      },
      {
        path: '/account',
        element: <Account />,
      },
      {
        path: '/variation-detail/:projectId/:variationId/:revisionId',
        element: <VariationDetail />,
      },
      {
        path: '/rfi-detail/:projectId/:rfiId',
        element: <RfiDetail />,
      },
      {
        path: '/add-variation',
        element: <AddVariation />,
      },
    ],
  },
  {
    element: <PublicRoute />,
    children: [
      {
        element: <ClientPublicVariation />,
        path: '/variation/:token/:variationId?/:revisionId?',
      },
    ],
  },
];

export default routes;
